import { provideHttpClient } from '@angular/common/http';
import { APP_ID, APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  API_BASE_URL,
  FULL_STORAGE_URL,
  SiteSettingsApiClient,
  SiteTitleService,
  STORAGE_FILE_DOWNLOAD_URL_GETTER,
  STORAGE_FILE_URL_GETTER,
  STORAGE_URL,
  StorageFileUrlGetterFn,
} from '@services';
import { AppConfigService } from '@services/app-config.service';

import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorResponseService } from './pages/site/services/error-response.service';

export function storageFileUrlGetterFactory(storageUrl: string): StorageFileUrlGetterFn {
  return (id) => `${storageUrl}${id}`;
}

export function storageFileDownloadUrlGetterFactory(storageUrl: string): StorageFileUrlGetterFn {
  return (id) => `${storageUrl}/file/download/${id}`;
}

export function fullStorageUrlFactory(storageUrl: string): string {
  return `${storageUrl}/file/get/`;
}

registerLocaleData(localeRu, 'ru-RU');

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, AppRoutingModule, BrowserAnimationsModule],
  providers: [
    AppConfigService,
    { provide: APP_ID, useValue: 'serverApp' },
    {
      provide: APP_INITIALIZER,
      deps: [AppConfigService],
      useFactory: (appConfig: AppConfigService) => () => appConfig.init$(),
      multi: true,
    },
    {
      provide: API_BASE_URL,
      deps: [AppConfigService],
      useFactory: (cfg: AppConfigService) => cfg.get()?.api,
    },
    {
      provide: STORAGE_URL,
      deps: [AppConfigService],
      useFactory: (cfg: AppConfigService) => cfg.get()?.storageUrl,
    },
    {
      provide: FULL_STORAGE_URL,
      deps: [STORAGE_URL],
      useFactory: fullStorageUrlFactory,
    },
    {
      provide: STORAGE_FILE_URL_GETTER,
      deps: [FULL_STORAGE_URL],
      useFactory: storageFileUrlGetterFactory,
    },
    {
      provide: STORAGE_FILE_DOWNLOAD_URL_GETTER,
      deps: [STORAGE_URL],
      useFactory: storageFileDownloadUrlGetterFactory,
    },
    {
      provide: LOCALE_ID,
      useValue: 'ru-RU',
    },
    SiteSettingsApiClient,
    SiteTitleService,
    ErrorResponseService,
    provideClientHydration(withHttpTransferCacheOptions({ includePostRequests: false })),
    provideHttpClient(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
